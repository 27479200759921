import React from 'react';
import { Divider, Box } from '@chakra-ui/react';

import Nav from "../components/Nav";
import Service from "../components/Service";
import Footer from "../components/Footer";

const ServiceScreen = () => {
  return (
    <Box flex='1' mt={6} overflow='auto'>
      <Nav />
      <Service />
      <Divider mt="12" />
      <Footer />
    </Box>
  )
}

export default ServiceScreen;