import React from 'react';
import { Box, Flex, Heading, Text, Icon, Stack, UnorderedList, ListItem, Image } from "@chakra-ui/react";
import { FaBullseye, FaRocket, FaCogs, FaHeart } from 'react-icons/fa';

const Card = ({ title, icon, description }) => (
  <Box
    maxW="md"
    borderWidth="3px"
    borderRadius="lg"
    overflow="hidden"
    shadow="md"
    color="white"
    p={4}
    borderColor="white"
    boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
    height={{ base: '200px', md: '180px' }}
    width={['300px',]}
    _hover={{
      transform: 'scale(1.05)',
    }}
  >
    <Heading as="h3" size="md" mb={4} fontWeight="bold" color="white">
      {title}
    </Heading>
    {Array.isArray(description) ? (
      <Flex direction="column" alignItems="center">
        <Icon as={icon} boxSize={6} color="white" />
        <UnorderedList>
          {description.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </UnorderedList>
      </Flex>
    ) : (
      <Flex alignItems="center" m={4}>
        <Icon as={icon} boxSize={6} color="white" />
        <Text ml={2}>{description}</Text>
      </Flex>
    )}
  </Box>
);

const Service = () => {
  return (
    <Box position="relative" backgroundColor="brandPurple"> 
    <Flex
        direction="column"
        align="center"
        maxW={{ xl: "1200px" }}
        m="0 auto"
        p={8}
        >
        <Flex
            align="center"
            justify={{ base: "center", md: "space-around", xl: "space-between" }}
            direction={{ base: "column-reverse", md: "row" }}
            wrap="no-wrap"
            minH="70vh"
        >
            <Stack
                spacing={4}
                w={{ base: "80%", md: "60%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading 
                    as="h4" 
                    size="md"
                    fontWeight="bold"
                    color="white"
                    textAlign={["center", "center", "left", "left"]}
                    >
                    Our Story
                </Heading>
                <Text 
                    as="h4" 
                    fontSize="md" 
                    color="white" 
                    opacity="0.8"
                    textAlign={["center", "center", "left", "left"]}
                    >
                    What are We Doing ?  
                </Text>
                
                    {/* Cards */}
                    <Box gridTemplateColumns={{ base: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}>
                        <Stack
                        direction={{ base: 'column', sm: 'row', md: 'row', lg: 'row' }}
                        justify="center"
                        spacing={2}
                        wrap="wrap"
                        align="center"
                        >
                        <Card
                            title="Our Mission"
                            icon={FaBullseye}
                            description="Creating Meaningful And Intuitive Call-Center, And Customer Support Experts Globally"
                        />
                        <Card
                            title="Our Vision"
                            icon={FaRocket}
                            description="To Be The Leading Company Of Choice Of Customer Support And Call-Center Training."
                        />
                        <Card
                            title="Core Competencies"
                            icon={FaCogs}
                            description={['Creativity', 'Innovation']}
                            />
                        <Card
                            title="Core Values"
                            icon={FaHeart}
                            description={['Integrity', 'Leadership']}
                            />
                        </Stack>
                    </Box>
               
              </Stack>
              <Box w={{ base: "100%",  md: "40%" }} mb={{ base: 12, md: 0 }} h={{ md: "auto"}}>
                <Image
                    src="https://source.unsplash.com/700x500/?customer"
                    size="100%"
                    rounded="1rem"
                    shadow="2xl"
                />
            </Box>
            
        </Flex>
      </Flex>     
    </Box>
  )
}

export default Service;
