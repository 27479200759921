import React from 'react';
import { Flex, Heading, Text, Icon} from '@chakra-ui/react';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { GiTeacher } from "react-icons/gi";
import { TbReplaceFilled } from "react-icons/tb";

import Button from './Button';

const ContactInHome = () => {
  return (
    <Flex width="100%" flexDirection="column" alignItems="center" mt="24">
      <Heading
        as="h3"
        mb="20"
        fontSize={['2xl', '2xl', '3xl', '3xl', '3xl']}
        textAlign="center"
        marginX="auto"
      >
        We offer 3 main call-center training services
      </Heading>
      <Flex
        width="90%"
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxW="1280px"
        mb="12"
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          maxW="380px"
          mb={['8', '8', '8', '0', '0']}
        >
          <Icon 
            as={LiaChalkboardTeacherSolid} 
            color="brandPurple"          
            width="50px"
            height="50px"
            mr="18px"
          />
          <Flex flexDirection="column" fontSize="xl">
            <Text fontWeight="bold">Training:</Text>
            <Text fontSize="sm">
               We are a call-center training company that enrolls traininees, 
               trains them to the fullest capacity to compete effectively in the customer support endeavors. 
               If you are a post-highschool or any person whose dream is to pursue call-enter and customer support, 
               then this is home to the best training.
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          maxW="380px"
          mb={['8', '8', '8', '0', '0']}
        >
          <Icon 
            as={GiTeacher} 
            color="brandPurple"          
            width="50px"
            height="50px"
            mr="18px"
          />
          <Flex flexDirection="column" fontSize="xl">
            <Text fontWeight="bold">Company Training:</Text>
            <Text fontSize="sm">
                We offer training to company call-center and/or customer support staff with training. 
                We also organize bootcamps for the same in which call-center/customer support staff meet to comb through 
                how to make the most out of the best of customer support.
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          maxW="380px"
          mb={['8', '8', '8', '0', '0']}
        >
          <Icon 
            as={TbReplaceFilled} 
            color="brandPurple"          
            width="50px"
            height="50px"
            mr="18px"
          />
          <Flex flexDirection="column" fontSize="xl">
            <Text fontWeight="bold">Placement: </Text>
            <Text fontSize="sm">
                As the best trainers, we have a pool of the best service providers on matters call center and 
                customer support. From the pool of 1000+, we carefully select the best within the shortest time for the most 
                fitting job. From more than 100+ monthly requests, our trainees enjoy high probability of being placed.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button>Consult us now</Button>
    </Flex>
  )
}
export default ContactInHome;
