import React from 'react';

import { Flex, Text, Heading, Grid, Image } from '@chakra-ui/react';

const OurClients = () => {
  return (
    <Flex flexDirection="column" alignItems="center" mt="24">
      <Heading as="h3" textTransform="uppercase" mb="20px">
        Our Clients
      </Heading>
      <Text fontSize="16px" mb="32px" maxW="460px" textAlign="center">
        Below are some of our clients
      </Text>
      <Grid
        gridTemplateColumns="repeat(3, 1fr)"
        gridGap="26px"
        px={['8', '24']}
      >
        <Image
          src= {require('../images/achievement1.png')}
          gridColumn={['1 / 2', '1 / 3', 'auto', 'auto', 'auto']}
        />
        <Image
          src= {require('../images/2.png')}
          gridColumn={['1 / 2', '2 / 4', 'auto', 'auto', 'auto']}
        />
        <Image
          src= {require('../images/cv7.png')}
          gridColumn={['1 / 2', '1 / 3', 'auto', 'auto', 'auto']}
        />
        <Image
          src= {require('../images/4.png')}
          gridColumn={['1 / 2', '2 / 4', 'auto', 'auto', 'auto']}
        />
        <Image
          src= {require('../images/5.png')}
          gridColumn={['1 / 2', '1 / 3', 'auto', 'auto', 'auto']}
        />
        <Image
          src= {require('../images/3.png')}
          gridColumn={['1 / 2', '2 / 4', 'auto', 'auto', 'auto']}
        />
      </Grid>
    </Flex>
  )
}

export default OurClients;