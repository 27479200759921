import React from 'react';
import { Divider, Box } from '@chakra-ui/react';

import Nav from "../components/Nav";
import About from "../components/About";
import Footer from "../components/Footer";

const AboutScreen = () => {
  return (
    <Box flex='1' mt={6} overflow='auto'>
      <Nav />
      <About />
      <Divider mt="12" />
      <Footer />
    </Box>
  )
}

export default AboutScreen;