import React, { useState } from 'react';
import { Box, Flex, Button, Stack, Link } from '@chakra-ui/react';
import { HiMenu } from 'react-icons/hi';
import { Link as RouterLink } from 'react-router-dom';

import Logo from './Logo';

const MenuItems = ({ to, children }) => (
  <RouterLink to={to} style={{ textDecoration: 'none' }}>
    <Button
      variant="link"
      mt={[4, 4, 0, 0]}
      mr={'36px'}
      display="block"
      fontWeight="medium" 
      fontSize="md"
      color="black" 
      _hover={{
        color: 'brandPurple', 
        textDecoration: 'none', 
      }}
    >
      {children}
    </Button>
  </RouterLink>
);


const Nav = () => {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  return (
    <Flex
      as="nav"
      position="fixed"
      top="0"
      zIndex="999"
      width="100%"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingX={['1.5em', '1.5em', '1.5em', '15%']}
      paddingY="1.5em"
      bg="rgba(255,255,255,.8)"
      color="black"
      textTransform="uppercase"
    >
      <Flex align="center" mr={5}>
        <Logo />
      </Flex>

      <Box display={['block', 'block', 'none', 'none']} onClick={handleToggle}>
        <HiMenu color="brandPurple" w="6" h="10" mr={4} />
      </Box>

      <Box
        display={[
          show ? 'block' : 'none',
          show ? 'block' : 'none',
          'flex',
          'flex',
        ]}
        width={['full', 'full', 'auto', 'auto']}
        marginTop={['20px', '20px', 'auto', 'auto']}
        textAlign="center"
        alignItems="center"
      >
        <MenuItems to="/">Home</MenuItems>
        <MenuItems to="/about">About</MenuItems>
        <MenuItems to="/service">Services</MenuItems>
        <MenuItems to="/contact">Contact</MenuItems>
        <Stack spacing={0} direction="row" align="center" marginLeft={40} mt={{ base: 8, md: 0 }}>
          <Link as={RouterLink} to="/" style={{ textDecoration: 'none' }}>
            <Button
              variant="solid" 
              color="white" 
              bg="brandPurple" 
              _hover={{
                bg: 'purple.600', 
              }}
            >
              Log In
            </Button>
          </Link>
        </Stack>
      </Box>
    </Flex>
  );
};

export default Nav;
