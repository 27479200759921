import React from 'react';

import { Heading, Text, Flex, Icon } from '@chakra-ui/react';
import { HiPhoneOutgoing } from 'react-icons/hi';

const Logo = () => {
  return (
    <>
      <Heading as="h4" size="lg" color="brandPurple" textTransform="uppercase">
        <Flex>
          <Icon as={HiPhoneOutgoing} color='brandPurple' w='5' h='7' />
            <Heading 
              as='h1' 
              color='brandPurple' 
              fontWeight='bold' 
              size='md' 
              ml={2}
              letterSpacing='wide'
            >
              <Text display="inline">Contact-In.</Text>
            </Heading>
        </Flex>
      </Heading>
    </>
  )
}

export default Logo;
