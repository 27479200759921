import { Flex } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './screens/Home';
import AboutScreen from './screens/AboutScreen';
import ServiceScreen from './screens/ServiceScreen';
import ContactScreen from './screens/ContactScreen';

function App() {
  return (
    <BrowserRouter>
      <Flex as="main" direction="row" bgColor="white.900">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/service" element={<ServiceScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
        </Routes>
      </Flex>
    </BrowserRouter>
  );
}

export default App;
